<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addNewInvoiceForm"
        ref="addNewInvoiceForm"
        v-model="isFormValid"
        @submit.prevent="submitNewInvoiceForm"
      >
        <v-row
          class="pt-4"
          v-if="!isPreviewMode"
        >
          <v-col>
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="showSaleDatePicker"
                  v-bind="attributes.menu"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(invoice.saleDate)"
                      :rules="showSaleDatePicker ? [] : [rules.required]"
                      label="Data sprzedaży"
                      placeholder="Wybierz datę sprzedaży"
                      v-bind="{ ...attrs, ...attributes.textField }"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <Icon
                          name="date"
                          size="small"
                          class="mr-2"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.saleDate"
                    v-bind="attributes.datePicker"
                    :rules="showSaleDatePicker ? [] : [rules.required]"
                    @input="showSaleDatePicker = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="showIssueDatePicker"
                  v-bind="attributes.menu"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(invoice.issueDate)"
                      :rules="showIssueDatePicker ? [] : [rules.required]"
                      label="Data wystawienia"
                      placeholder="Wybierz datę wystawienia"
                      v-bind="{ ...attrs, ...attributes.textField }"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <Icon
                          name="date"
                          size="small"
                          class="mr-2"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.issueDate"
                    v-bind="attributes.datePicker"
                    :rules="showIssueDatePicker ? [] : [rules.required]"
                    @input="showIssueDatePicker = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-menu
                  v-model="showDueDatePicker"
                  v-bind="attributes.menu"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="formatDate(invoice.paymentDueDate)"
                      :rules="showDueDatePicker ? [] : [rules.required]"
                      label="Termin płatności"
                      placeholder="Wybierz termin płatności"
                      v-bind="{ ...attrs, ...attributes.textField }"
                      v-on="on"
                    >
                      <template #prepend-inner>
                        <Icon
                          name="date"
                          size="small"
                          class="mr-2"
                        />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="invoice.paymentDueDate"
                    v-bind="attributes.datePicker"
                    :rules="showDueDatePicker ? [] : [rules.required]"
                    :min="paymentMinDate"
                    @input="showDueDatePicker = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="invoice.settlementType"
                  :items="individualSettlementTypes"
                  label="Typ dokumentu"
                  placeholder="Zmień typ dokumentu klienta indywidualnego"
                  outlined
                  dense
                  :disabled="!isIndividualClient || isGeneratingFromInvoice"
                />
              </v-col>

              <v-col cols="12">
                <ForwardDocument
                  :item="invoice"
                  @update="updateContacts"
                />
              </v-col>

              <v-col
                cols="12"
                class="mt-3"
              >
                <v-textarea
                  v-model="invoice.notes"
                  label="Uwagi"
                  placeholder="Uwagi dotyczące dokumentu"
                  outlined
                  hide-details
                  dense
                />
              </v-col>

              <v-col
                cols="12"
                class="pb-0 pl-4 mb-0 mt-n3"
                v-if="!invoice.contactIds.length"
              >
                <div class="error--text">
                  Faktura nie zostanie wysłana
                </div>
              </v-col>
              <v-col
                cols="12"
                class="pb-0 pl-4 mb-0 mt-n3"
                v-if="showAlreadyCreatedDocumentError"
              >
                <div class="error--text">
                  Faktura do {{ selectedItems.length ? 'jednego ze zleceń': 'zlecenia' }} już istnieje
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="7"
            v-if="isCustomServiceInvoice"
          >
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <template v-if="showInvoiceItemTemplateForm">
                  <h3 class="mb-6 black--text">
                    Dodaj usługę
                  </h3>
                  <v-autocomplete
                    :items="invoiceItemTemplates"
                    label="Usługa"
                    item-text="name"
                    return-object
                    placeholder="Wybierz ze zdefiniowanych usług"
                    outlined
                    hide-details
                    dense
                    class="mb-6"
                    @change="chooseInvoiceItemTemplate"
                  />
                  <v-form
                    id="addNewInvoiceItemTemplateForm"
                    ref="addNewInvoiceItemTemplateForm"
                    class="custom-form"
                    @submit.prevent="addNewCustomService"
                  >
                    <InvoiceItemTemplateForm
                      :invoice-item-template="invoiceItemTemplate"
                      @update="updateFormValue"
                    />
                  </v-form>
                </template>
                <template v-else>
                  <h3 class="mb-6 black--text">
                    Usługi
                  </h3>
                  <template v-if="invoice.customServices.length">
                    <div
                      class="d-flex align-center"
                      v-for="(service, index) in invoice.customServices"
                      :key="'service-' + index"
                    >
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            outlined
                            label="Nazwa usługi"
                            v-model="invoice.customServices[index].name"
                            :rules="[rules.required]"
                            validate-on-blur
                            placeholder="Wpisz nazwę usługi"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            outlined
                            label="Cena netto"
                            v-model="invoice.customServices[index].netValue"
                            :rules="[
                              rules.required,
                              rules.money,
                              rules.lowerThan(10000001)
                            ]"
                            suffix="PLN"
                            validate-on-blur
                            placeholder="Wpisz cenę netto usługi [PLN]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            outlined
                            label="Stawka vat"
                            v-model="invoice.customServices[index].vatPercentage"
                            :rules="[rules.required, rules.lowerThan(101)]"
                            validate-on-blur
                            suffix="%"
                            placeholder="Wpisz stawkę vat [%]"
                          />
                        </v-col>
                        <v-col cols="2">
                          <v-text-field
                            outlined
                            label="Ilość"
                            v-model.number="invoice.customServices[index].quantity"
                            :rules="[rules.number, rules.lowerThan(101), rules.greaterThan(0)]"
                            type="number"
                            validate-on-blur
                            placeholder="1"
                          />
                        </v-col>
                      </v-row>
                      <v-btn
                        icon
                        small
                        class="mb-5 ml-1"
                        @click="removeCustomService(index)"
                      >
                        <v-icon color="error">
                          mdi-close
                        </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </template>
              </v-col>

              <v-col
                cols="12"
                class="py-0 mt-n2"
              >
                <v-btn
                  v-if="showInvoiceItemTemplateForm"
                  text
                  class="mr-1"
                  color="primary"
                  small
                  type="submit"
                  form="addNewInvoiceItemTemplateForm"
                >
                  Dodaj usługę
                </v-btn>
                <v-btn
                  v-if="showInvoiceItemTemplateForm"
                  text
                  class="mr-1"
                  color="primary"
                  small
                  @click="submitNewInvoiceItemTemplateForm"
                >
                  Dodaj i zapisz usługę
                </v-btn>
                <v-input
                  v-else-if="
                    !showInvoiceItemTemplateForm &&
                      !invoice.customServices.length
                  "
                  :value="invoice.customServices"
                  :rules="[rules.required]"
                  hide-details
                  class="validated-label d-inline-block"
                >
                  <h4>
                    Brak usług
                  </h4>
                </v-input>
                <v-btn
                  text
                  :color="showInvoiceItemTemplateForm ? 'black' : 'primary'"
                  small
                  @click="
                    showInvoiceItemTemplateForm = !showInvoiceItemTemplateForm
                  "
                >
                  {{ showInvoiceItemTemplateForm ? 'Anuluj' : 'Dodaj usługę' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <template />
        </v-row>

        <InvoicePreview
          v-else
          :preview="preview"
        />
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover mr-4"
        :loading="isProcessing"
        @click="previewInvoice"
      >
        {{ isPreviewMode ? 'Ukryj podgląd' : 'Podgląd danych' }}
      </v-btn>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="addNewInvoiceForm"
        v-shortkey="{ enter: ['enter'] }"
        @shortkey.native="submitNewInvoiceForm"
      >
        Wygeneruj
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import InvoicePreview from './Partials/InvoicePreview'
import InvoiceItemTemplateForm from './Partials/InvoiceItemTemplateForm'
import ForwardDocument from '../../components/Forms/Partials/ForwardDocument'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'
import updateFormValue from '../../mixins/updateFormValue'
import attributes from '../../const/datePickerAttrributes'

import {
  dateStringFormat,
  filterNullFields,
  parseAsBasicUnit,
} from '../../utils'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'
import DateTime from 'luxon/src/datetime'

export default {
  props: {
    isCustomServiceInvoice: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DialogFormWrapper,
    ForwardDocument,
    InvoicePreview,
    InvoiceItemTemplateForm,
  },
  mixins: [afterFormSubmitted, updateFormValue],
  data() {
    return {
      rules,
      attributes,
      isFormValid: true,
      showSaleDatePicker: false,
      showIssueDatePicker: false,
      showDueDatePicker: false,
      invoice: {
        saleDate: new Date().toISOString().substr(0, 10),
        issueDate: new Date().toISOString().substr(0, 10),
        paymentDueDate: new Date().toISOString().substr(0, 10),
        orderIds: [],
        settlementType: null,
        parentInvoiceId: null,
        contactIds: [],
        externalContactEmails: [],
        customServices: [],
        notes: null
      },
      preview: {},
      isPreviewMode: false,
      showInvoiceItemTemplateForm: false,
      invoiceItemTemplate: {},
    }
  },
  watch: {
    'invoice.issueDate': function (val) {
      const issueDate = DateTime.fromISO(val)
      const paymentDate = DateTime.fromISO(this.invoice.paymentDueDate)
      if (paymentDate < issueDate) {
        this.invoice.paymentDueDate = val
      }
      this.invoice.saleDate = val
    }
  },
  computed: {
    ...mapState({
      isProcessing: state => state.invoices.isProcessing,
      selectedItems: state => state.orders.selectedItems,
      orders: state => state.orders.items,
      item: state => state.layout.dialog.item,
      clientContacts: state => state.client.entity?.contacts,
      invoiceItemTemplates: state => state.invoiceItemTemplates.items,
      department: state => state.core.department,
      individualSettlementTypes: state =>
        state.core.filters.individualSettlementTypes
    }),
    payer() {
      return this.item
        ? this.item.payer
        : this.orders.find(order => order.id === this.selectedItems?.[0])
          ?.payer
    },
    clientContactsWithEmail() {
      return this.clientContacts?.filter(contact => !!contact.email) || []
    },
    isIndividualClient() {
      return this.payer?.clientType === 'Osoba fizyczna'
    },
    isGeneratingFromInvoice() {
      return this.item?.invoiceNumber
    },
    paymentMinDate() {
      return this.invoice?.issueDate || DateTime.utc().toISODate()
    },
    showAlreadyCreatedDocumentError() {
      if (this.selectedItems.length) {
        // check if any of selected orders has document
        return !!this.selectedItems.reduce((acc, orderId) => {
          return Number(!this.orders.find(order => order.id === orderId)?.withoutDocuments) + acc
        }, 0)
      }
      return !this.isCustomServiceInvoice && !this.item?.withoutDocuments
    },
    externalContactEmails() {
      return this.invoice.contactIds.filter(
        element => typeof element === 'string'
      )
    }
  },
  created() {
    this.getInvoiceItemTemplates()
    if (this.payer) {
      this.getClient(this.payer.id).then(() => {
        this.invoice.contactIds = this.clientContactsWithEmail
          .map(contact => contact.invoiceReceivable && contact.id)
          .filter(id => !!id)
      })
    }
  },
  mounted() {
    this.showInvoiceItemTemplateForm = this.isCustomServiceInvoice
    if (this.isGeneratingFromInvoice) {
      this.invoice.parentInvoiceId = this.item.id
      this.invoice.settlementType = 'Faktura imienna'
    } else {
      if (this.isIndividualClient) {
        this.invoice.settlementType = this.payer?.settlementType
      }
      this.invoice.orderIds = this.selectedItems.length
        ? this.selectedItems
        : [this.item.id]
    }
    if (this.item?.payment?.paymentDueDays) {
      const dueDate = DateTime.fromISO(this.invoice.paymentDueDate)
        .plus({ day: this.item.payment.paymentDueDays })
        .toISODate()
      this.invoice.paymentDueDate = dueDate
    } else {
      const dueDate = DateTime.local().plus({ day: 7 }).toISODate()
      this.invoice.paymentDueDate = dueDate
    }
  },
  methods: {
    ...mapActions({
      addNewInvoice: 'invoices/addNewItem',
      getInvoicePreview: 'invoice/getInvoicePreview',
      getClient: 'client/getSingleClient',
      toggleMultiselectStatus: 'orders/toggleMultiselectStatus',
      setDialogSize: 'layout/setDialogSize',
      getInvoiceItemTemplates: 'invoiceItemTemplates/getItems',
      addNewInvoiceItemTemplate: 'invoiceItemTemplates/addNewItem'
    }),
    updateContacts({ contactIds, externalContactEmails }) {
      this.invoice.contactIds = contactIds
      this.invoice.externalContactEmails = externalContactEmails
    },
    formatDate(date) {
      return date ? dateStringFormat(date) : ''
    },
    previewInvoice() {
      this.isPreviewMode = !this.isPreviewMode
      this.$refs.addNewInvoiceForm.validate()

      if (this.isFormValid) {
        const params = { ...this.invoice }
        params.customServices = this.multiplyCustomServicesValue(
          params.customServices
        )
        this.getInvoicePreview(filterNullFields(params)).then(invoice => {
          this.preview = invoice
        })
      }
    },
    chooseInvoiceItemTemplate(template) {
      this.invoiceItemTemplate = {
        ...template,
        netValue: template.netValue / 100
      }
    },
    submitNewInvoiceItemTemplateForm() {
      if (this.$refs.addNewInvoiceItemTemplateForm.validate()) {
        const netValue = parseAsBasicUnit(
          this.invoiceItemTemplate.netValue,
          100
        )
        const params = {
          ...this.invoiceItemTemplate,
          netValue,
          departmentId: this.department.id
        }

        this.addNewInvoiceItemTemplate({ params }).then(template => {
          this.getInvoiceItemTemplates()
          this.showInvoiceItemTemplateForm = false
          this.invoice.customServices.push({
            ...template,
            quantity: 1,
            netValue: template.netValue / 100
          })
          this.invoiceItemTemplate = {}
        })
      }
    },
    multiplyCustomServicesValue(params) {
      return params.map(({ quantity, ...service }) => {
        const singleService = { ...service, netValue: parseAsBasicUnit(service.netValue, 100) }
        return [...new Array(+quantity || 1).fill(singleService)]
      }).flat()
    },
    addNewCustomService() {
      if (this.$refs.addNewInvoiceItemTemplateForm.validate()) {
        this.invoice.customServices.push({ ...this.invoiceItemTemplate, quantity: 1 })
        this.invoiceItemTemplate = {}
        this.showInvoiceItemTemplateForm = false
      }
    },
    removeCustomService(index) {
      this.$delete(this.invoice.customServices, index)
    },
    submitNewInvoiceForm() {
      this.$refs.addNewInvoiceForm.validate()

      const params = { ...this.invoice }
      if (this.isCustomServiceInvoice) {
        params.customServices = this.multiplyCustomServicesValue(
          params.customServices
        )
      } else delete params.customServices
      if (this.isFormValid) {
        this.addNewInvoice({ params }).then(data => {
          const { settlementType, invoiceNumber } = data
          const actionLabel =
            settlementType === 'Paragon'
              ? 'został wygenerowany'
              : 'została wygenerowana'
          this.afterFormSubmitted(
            `${settlementType} nr ${invoiceNumber} ${actionLabel}`
          )
          this.toggleMultiselectStatus(false)
        })
      }
    },
  }
}
</script>
